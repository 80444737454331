<script lang="ts" setup>
import LineXClose from '@docue/docue-ui-v2/icons/LineXClose.vue'
import SolidStar06 from '@docue/docue-ui-v2/icons/SolidStar06.vue'
import type { FeatureKey } from '~/types/auth'

const props = defineProps<{
  feature?: FeatureKey
  enabled?: boolean
  purchasable?: boolean
}>()

const { t } = useI18n()
const { hasFeature } = useFeatureAccess()
const { hasValidSubscription } = useSubscription()
const { isModalOpen, openModal, closeModal } = useModal()
const { isModalOpen: isContactUsModalOpen, openModal: openContactUsModal } = useModal()

const enabled = computed(() => props.enabled || (props.feature && hasFeature(props.feature)))

const action = (cb: () => any, event?: Event) => {
  if (!enabled.value) {
    openModal()
    event?.preventDefault()
    return
  }

  cb()
}

const message = computed(() =>
  hasValidSubscription.value
    ? t('feature-blocker.invalid-sub')
    : t('feature-blocker.no-sub'),
)

const isSubscribeModalOpen = useSubscriptionModalOpenState()
const {
  isActive: isSubscriptionFlowTestInUse,
} = useTest('subscription-flow-v2')

const subscribeButtonAttributes = computed(() => (
  isSubscriptionFlowTestInUse.value && !hasValidSubscription.value
    ? { onClick: () => (isSubscribeModalOpen.value = true) }
    : { to: `/subscribe?return=${window.location.toString()}`, external: true }),
)
</script>

<template>
  <slot
    :enabled="enabled"
    :action="action"
  />
  <ContactUsModal
    v-if="isContactUsModalOpen"
    v-model="isContactUsModalOpen"
    @done="closeModal"
  />
  <DTModal v-model="isModalOpen">
    <DTModalBody>
      <DTIconButton
        variant="plain"
        @click.stop="closeModal()"
      >
        <LineXClose class="size-5 text-gray-800" />
      </DTIconButton>
      <div class="mt-4 flex flex-col items-center gap-4">
        <SolidStar06 class="inline-block w-7 text-yellow-400" />

        <span class="text-center text-lg font-semibold text-gray-800">
          {{ message }}
        </span>

        <DTButton
          v-if="purchasable"
          variant="blue"
          class="mt-2 w-full"
          @click="openContactUsModal()"
        >
          {{ $t('contact-us') }}
        </DTButton>
        <DTButton
          v-else
          v-bind="subscribeButtonAttributes"
          variant="blue"
          class="mt-2 w-full"
        >
          {{ $t('feature-blocker.cta') }}
        </DTButton>
      </div>
    </DTModalBody>
  </DTModal>
</template>
